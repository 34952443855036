import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: "Montserrat",
    padding: "2rem",
    maxWidth: "30rem",
    margin: "0 auto",
    [theme.breakpoints.up(1280)]: {
      maxWidth: "44rem",
      boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
      borderRadius: 30,
      margin: "0 4rem",
    },
    "& .top": {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
      fontWeight: "bold",
      color: "#282828",
      "& img": {
        width: "3rem",
      },
    },
    "& .subtitle": {
      fontWeight: 300,
      color: theme.palette.text.secondary,
      width: "15rem",
      fontSize: "0.85rem",
      marginTop: "1rem",
      [theme.breakpoints.down(1280)]: {
        width: "100%",
      },
    },
  },
  containerInputs: {
    [theme.breakpoints.up(1280)]: {
      display: "flex",
      justifyContent: "space-between",
      "& >div": {
        width: "47%",
      },
    },
  },
  input: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    marginTop: "1rem",
    marginBottom: "2rem",
    "& span": {
      color: "#646464",
      display: "block",
      fontSize: "0.75rem",
      fontWeight: 600,
      letterSpacing: "0.6px",
    },
  },
  primaryButton: {
    width: "100%",
    marginTop: "2rem",
    [theme.breakpoints.up(1280)]: {
      marginTop: "2rem",
      width: "20rem",
      display: "flex",
    },
  },
  loading: {
    margin: "0 auto",
    padding: "2rem 0 4rem",
    background: theme.palette.primary.main,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999,
    "& img": {
      margin: "0 auto",
    },
  },
  textLoading: {
    marginTop: "13rem",
    maxWidth: "16rem",
    margin: "0 auto",
    "& span": {
      display: "block",
    },
    "& .title": {
      fontWeight: "bold",
      textTransform: "uppercase",
      margin: "2rem auto",
      width: "10.5rem",
      color: "white",
    },
    "& .subtitle": {
      textAlign: "center",
      color: "white",
    },
    "& .title2": {
      fontWeight: "bold",
      textTransform: "uppercase",
      margin: "2rem auto",
      width: "7.5rem",
      color: "white",
    },
    "& .subtitle2": {
      textAlign: "center",
      color: "white",
      width: "10rem",
      margin: "0 auto",
    },
    "& svg": {
      background: "red",
    },
  },
  textLoading2: {
    marginTop: "9rem",
  },
  error: {
    display: "flex",
    alignItems: "start",
    gap: "1rem",
    padding: "1rem 0",
    border: "1px solid #cc232a",
    borderLeft: "none",
    borderRight: "none",
    "& .title": {
      color: "#cc232a",
      textTransform: "uppercase",
      display: "block",
      fontWeight: "bold",
    },
    "& .text": {
      display: "block",
      color: theme.palette.text.secondary,
      width: "15rem",
      fontWeight: 300,
      fontSize: "0.875rem",
    },
  },
  button: {
    width: "335px",
    margin: "2rem auto 0",
    background: "#0A5669",
    display: "flex",
  },

  //Modal
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "2.1875rem",
      border: "2px solid #ffffff",
    },
  },
  containerModal: {
    borderRadius: "2.1875rem",
    border: "solid 2px grey",
    "& .pantalla": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "2rem",
      paddingTop: "0.5rem",
      borderRadius: "2.1875rem",
      border: "solid 1px #ffffff",
      backgroundColor: "#F4F3F3",
      [theme.breakpoints.down(1240)]: {
        padding: "1.5rem",
      },
    },
  },
  topSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    marginTop: "0.5rem",
    "& .title": {
      fontSize: "1.5rem",
      marginTop: 0,
      fontWeight: "bold",
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      textAlign: "left",
      display: "flex",
      gap: "1rem",
    },
    "& img": {
      width: "1.5rem",
    },
  },
  textSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "felx-start",
    marginBottom: "2rem",
    "& .text": {
      fontSize: "0.85rem",
      fontWeight: 500,
      color: theme.palette.text.primary,
      fontFamily: "Montserrat",
      margin: "0.3rem 0",
    },
  },
  modalInfo: {
    marginBottom: "1rem",
    "& .info": {
      display: "flex",
      gap: "1rem",
      marginBottom: "0.5rem",
    },
    "& .text2": {
      fontFamily: "Noto Sans, Montserrat",
      fontSize: "0.75rem",
      fontWeight: "bold",
      color: "#000000",
    },
  },
  buttonSection: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    "& button": {
      width: "12rem",
      [theme.breakpoints.down(1240)]: {
        margin: "1rem",
      },
    },
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  separator: {
    width: "30rem",
    height: "0.125rem",
    backgroundColor: "#ffffff",
    margin: "1rem auto",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      margin: "1rem 2rem",
    },
  },
  disclaimerText: {
    color: "#000000",
    fontSize: "12px",
    marginTop: "0.5rem",
    padding: "0 0 1rem 8px",
  },
}));
