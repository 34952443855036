import React, { useState } from "react";
import { useStyles } from "./AddDataAccount.style";
import { DUAL_BALL, SECURITY_ICON } from "images/AWS";
import { EYE_ICON_SECUNDARY, TARGET_ICON } from "images/GCP";
import { useCurrentUser } from "@apollo";
import { PasswordInput, RutInput } from "@components/Inputs";
import {
  ButtonPrimary,
  ButtonText,
  ButtonOutlined,
} from "@components/Reusables";
import { FinerioBankInterface } from "@interfaces";
import axios from "axios";
import { ALERT_ICON, CONNECTION_ICON, SUCCESSFUL } from "images/AWS/myAccount";
import { useMediaQuery } from "@material-ui/core";
import { AddAccountModal } from "../AddAccountModal/AddAccountModal";
import { Dialog } from "@material-ui/core";

interface AddDataAccountProps {
  selectBank: FinerioBankInterface;
  closeAddAccount: () => void;
  showMessage: () => void;
}

const AddDataAccount = ({
  selectBank,
  closeAddAccount,
  showMessage,
}: AddDataAccountProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1280px)");
  const { user: currentUser } = useCurrentUser();
  const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
  const [error, setError] = useState<string>(""); // "wrongCredentials" || "connectionError";
  const [successful, setSuccessful] = useState<boolean>(false);

  const [dataBank, setDataBank] = useState({
    username: "",
    password: "",
    bankId: selectBank.finerioBankId,
    customId: "",
  });

  const url = `${process.env.GATSBY_API_URL}/finerio/create/credential`;

  const handleClick = () => {
    setCreatingAccount(true);
    axios
      .post(url, dataBank)
      .then((response) => {
        // console.log(response.data)
        setSuccessful(true);
        setCreatingAccount(false);
        setError("");
        showMessage();
      })
      .catch((error) => {
        // console.log(error.response)
        if (error.response?.statusCode == 401 || error.response?.status == 401) {
          setError("wrongCredentials");
        } else {
          setError("connectionError");
        }
        setCreatingAccount(false);
        setSuccessful(false);
      });
  };

  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleClose = () => setOpenModal(false);
  const handleOpen = () => setOpenModal(true);

  return creatingAccount ? (
    <div className={classes.loading}>
      <img src={DUAL_BALL} />
      <div className={classes.textLoading}>
        <span className="title">Agregando cuenta</span>
        <span className="subtitle">
          Estamos rescatando tu información, estarás listo en menos de lo que te
          tomas un café
        </span>
      </div>
    </div>
  ) : successful ? (
    <>
      <div className={classes.loading}>
        <img src={SUCCESSFUL} />
        <div className={`${classes.textLoading} ${classes.textLoading2}`}>
          <span className="title2">¡ Felicidades !</span>
          <span className="subtitle2">Tu cuenta se sincronizó con éxito.</span>
        </div>{" "}
        <ButtonPrimary
          className={classes.button}
          noDegraded
          text="Listo"
          onClick={() => {
            closeAddAccount();
          }}
        />
      </div>
    </>
  ) : (
    <section className={classes.container}>
      <div className="top">
        <img key={selectBank.id} src={selectBank.icon} />
        {selectBank.name}
      </div>
      <p className="subtitle">
        Introduce tus claves de acceso a {selectBank.name}
      </p>
      <div className={classes.containerInputs}>
        <div className={classes.input}>
          <span>RUT</span>
          <RutInput
            label=""
            onChange={(e) => {
              setDataBank((prev: any) => ({
                ...prev,
                username: e.target.value as string,
                customId: currentUser?.id,
              }));
            }}
            value={dataBank.username}
          />
        </div>
        <div className={classes.input}>
          <span>CLAVE</span>
          <PasswordInput
            label=""
            onChange={(e) =>
              setDataBank((prev: any) => ({
                ...prev,
                password: e.target?.value as string,
              }))
            }
            value={dataBank.password}
          />
        </div>{" "}
      </div>

      {error && (
        <div className={classes.error}>
          <img
            src={
              error === "wrongCredentials"
                ? ALERT_ICON
                : error === "connectionError"
                ? CONNECTION_ICON
                : ""
            }
          />
          <div>
            <span className="title">
              {error === "wrongCredentials" && "Credenciales incorrectas"}
              {error === "connectionError" && "error de conexión"}
            </span>
            <span className={"text"}>
              {error === "wrongCredentials" &&
                "RUT o clave incorrecta, llevas x intentos"}
              {error === "connectionError" &&
                "No pudimos procesar tu solicitud, vuelve a intentarlo más tarde"}
            </span>
          </div>
        </div>
      )}
      <p className={classes.disclaimerText}>
        Tus credenciales serán encriptadas y nadie tendrá acceso a ellas.
      </p>
      <ButtonText
        text={
          <>
            <img src={SECURITY_ICON} />
            ¿Por qué te pedimos estas claves?{" "}
          </>
        }
        onClick={() => handleOpen()}
      />

      <Dialog open={openModal} onClose={handleClose} className={classes.dialog}>
        <div className={classes.containerModal}>
          <div className="pantalla">
            <div className={classes.topSection}>
              <div className="title">
                <img src={SECURITY_ICON} />
                <span>¿Por qué te pedimos estas claves?</span>
              </div>
            </div>
            <div className={classes.separator}></div>
            <div className={classes.textSection}>
              <span className="text">
                Te pedimos tus claves bancarias de acceso para leer tus
                transacciones y entregarte nuestro servicio. Ojo, son{" "}
                <strong>solo de consulta</strong>, no podemos realizar
                transacciones por ti.
              </span>
            </div>
            <div className={classes.modalInfo}>
              <div className="info">
                <img src={SECURITY_ICON} />
                <span className="text2">
                  Tu usuario y claves están encriptados y son estrictamente
                  confidenciales.
                </span>
              </div>
              <div className="info">
                <img src={EYE_ICON_SECUNDARY} />
                <span className="text2">
                  Toda la información de tus cuentas es solamente visible por tí
                  en la plataforma.{" "}
                </span>
              </div>
              <div className="info">
                <img src={TARGET_ICON} />
                <span className="text2">
                  El análisis y manejo de tu información se hace de forma
                  automática por algoritmos.{" "}
                </span>
              </div>
            </div>
            <div className={classes.buttonSection}>
              <ButtonPrimary
                text="Entendido"
                onClick={handleClose}
                secondaryVersion
                noDegraded
              />
            </div>
          </div>
        </div>
      </Dialog>

      <ButtonPrimary
        className={classes.primaryButton}
        text="Conectar"
        onClick={() => handleClick()}
        noDegraded
      />
    </section>
  );
};

export default AddDataAccount;
