import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import AddProduct from "@components/MyAccounts/AddProduct/AddProduct";
import AccountData from "@components/MyAccounts/AccountData/AccountData";
import MyProducts from "@components/MyAccounts/MyProducts/MyProducts";
import LayoutMyAccounts from "@components/MyAccounts/Layout/Layout";
import AddAccountWithFinerio from "@components/MyAccounts/AddAccountWithFinerio/AddAccountWithFinerio";
import { FINERIO_ICON } from "images/AWS/myAccount";
import AddDataAccount from "@components/MyAccounts/AddDataAccount/AddDataAccount";
import { createManualAccount, getFinerioAccounts, getFinerioTransactions } from "@apollo";
import { useFinerioAccountsTotals } from "@hooks";
import { SpinnerWithText } from "@components/Reusables";
import { delay } from "lodash";
import MonthBalance from "../components/monthBalance";
import MyBalanceTable from "../Habits/MyBalance/MyBalanceTable";
import { finerioTransactionsByCategory } from "@utils";
import RokinAdvice from "../components/rokinAdvice";
import CoolebraButton from "@components/Coolebra/coolebraButton";
import SubdirectoryArrowLeftRoundedIcon from '@mui/icons-material/SubdirectoryArrowLeftRounded';
import { useApolloClient } from "@apollo/client";
import AddProductButton from "../components/addProductButton";
import { LayoutOnBoarding } from "@components/Structural";

type Props = {
  fullScreen?: boolean;
  setFullScreen?: Dispatch<SetStateAction<boolean>>;
};

export const MyAccountsPage = ({ fullScreen, setFullScreen }: Props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1280px)");

  const { finerioAccounts, refetchFinerioAccounts } = getFinerioAccounts();
  const { finerioTransactions } = getFinerioTransactions();
  const [isAddProduct, setAddProduct] = useState<boolean>(false);
  const [stepProduct, setStepProduct] = useState(0);
  const [selectBank, setSelectBank] = useState(0);
  const [isProduct, setProduct] = useState<boolean>(false);
  const [isTransactions, setTransactions] = useState<boolean>(false);
  const [bankNotSelected, setBankNotSelected] = useState<Array<number>>([]);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const client = useApolloClient();


  const finerioGlobals = useFinerioAccountsTotals({
    finerioAccounts,
    bankNotSelected,
  });

  useEffect(() => {
    finerioAccounts.length !== 0 ? setProduct(true) : setProduct(false);
    finerioTransactions.length !== 0 ? setTransactions(true) : setTransactions(false);
  }, [finerioAccounts, finerioTransactions]);

  const closeAddAccount = () => {
    setStepProduct(0);
    setAddProduct(false);
  };

  const refetchAndDelayAccounts = (time) => {
    delay(
      function (content) {
        refetchFinerioAccounts();
        setShowSpinner(false);
      },
      time,
      "ready to refetch"
    );
  }

  const showMessage = () => {
    setShowSpinner(true);
    refetchAndDelayAccounts(60000)
  };

  setFullScreen(isAddProduct);

  const finerioData = finerioTransactionsByCategory(finerioTransactions);

  const handleNewAccount = (bankId: number) => {
    createManualAccount(client, { bankId: bankId });
    refetchAndDelayAccounts(1000)
    setAddProduct(false);
  }

  return (
    <>
      {!isAddProduct ? (
        <section className={classes.containerProd}>
        {isMobile ? (  
        <>
        {!isTransactions && (
          <div className={classes.noProduct}>
            <span className="title">¡Aún no tienes movimientos registrados!</span>
            <div className={classes.noProductSecond}>
              <span className="subtitle">Ingresa manualmente tus gastos e ingresos</span>
              <SubdirectoryArrowLeftRoundedIcon className="icon" />
            </div>
            {/* <div className={(!isProduct && !showSpinner) ? classes.noProduct : ""}>
              {showSpinner && (
                <SpinnerWithText
                  text={"Tus productos estarán disponibles prontamente"}
                />
              )}
              TODO: Rename everything related to finerio to our scrappers
              <AccountData finerioGlobals={finerioGlobals} /> 
            </div> */}
          </div>
        )}
          <div className={classes.misProductos}>
            <MonthBalance monthAmount={finerioGlobals.totalBalance}/>
            {isTransactions && (
              <>
                <MyBalanceTable data={finerioData} />
                <RokinAdvice selectedMonth={0}/>
              </>
            )}
            <CoolebraButton banks/>
            <MyProducts
              isProduct={isProduct}
              bankNotSelected={bankNotSelected}
              setBankNotSelected={setBankNotSelected}
              onAddProductClick={() => {
                setAddProduct(true);
              }}
            />
          </div>
          </>
        ):(
          <>
            <div className={classes.desktopFirst}>
            {!isTransactions && (
              <div className={classes.noProduct}>
                <span className="title">¡Aún no tienes movimientos registrados!</span>
                <div className={classes.noProductSecond}>
                  <span className="subtitle">Ingresa manualmente tus gastos e ingresos</span>
                  <SubdirectoryArrowLeftRoundedIcon className="icon" />
                </div>
              </div>
              )}
              <MonthBalance monthAmount={finerioGlobals.totalBalance}/>
              {isTransactions && (
                <div className={classes.tableDesktop}>
                  <MyBalanceTable data={finerioData} />
                  <RokinAdvice selectedMonth={0}/>
                </div>
              )}
            </div>
            <div className={classes.desktopSecond}>
              <AddProductButton onAddProductClick={() => {
                  setAddProduct(true);
                }}/>
              <div className={classes.myProductsDesktop}>
                {!isTransactions && (
                  <div className={classes.noProductSecondDesktop}>
                    <span className="subtitle">Agrega productos para ordenar tu plata</span>
                    <SubdirectoryArrowLeftRoundedIcon className="icon" />
                  </div>
                )}
                <MyProducts
                  isProduct={isProduct}
                  bankNotSelected={bankNotSelected}
                  setBankNotSelected={setBankNotSelected}
                  onAddProductClick={() => {
                    setAddProduct(true);
                  }}
                />
                <CoolebraButton banks/>
              </div>
            </div>
          </>
        )}
        </section>
      ) : (
        <>
        {isMobile ? (
          <LayoutMyAccounts
            title={
              stepProduct === 0 ? (
                "AGREGAR UN PRODUCTO"
              ) : (
                <span className={classes.title}>
                  Agregar cuenta con <img src={FINERIO_ICON} />
                </span>
              )
            }
            className={!isMobile ? classes.borderDesktop : "Mobile"}
            onBackClick={() => {
              stepProduct === 0 ? setAddProduct(false) : setStepProduct(0);
            }}
            isDesktop
          >
            {stepProduct === 0 ? (
              <AddAccountWithFinerio
                finerioAccounts={finerioAccounts}
                setStepProduct={setStepProduct}
                setSelectBank={setSelectBank}
                handleNewAccount={handleNewAccount}
              />
            ) : (
              <AddDataAccount
                closeAddAccount={closeAddAccount}
                selectBank={selectBank}
                showMessage={showMessage}
              />
            )}
          </LayoutMyAccounts>
        ) : (
          <LayoutOnBoarding activeFinance>
            <LayoutMyAccounts
              title={
                stepProduct === 0 ? (
                  "AGREGAR UN PRODUCTO"
                ) : (
                  <span className={classes.title}>
                    Agregar cuenta con <img src={FINERIO_ICON} />
                  </span>
                )
              }
              className={!isMobile ? classes.borderDesktop : "Mobile"}
              onBackClick={() => {
                stepProduct === 0 ? setAddProduct(false) : setStepProduct(0);
              }}
              isDesktop
            >
              {stepProduct === 0 ? (
                <AddAccountWithFinerio
                  finerioAccounts={finerioAccounts}
                  setStepProduct={setStepProduct}
                  setSelectBank={setSelectBank}
                  handleNewAccount={handleNewAccount}
                />
              ) : (
                <AddDataAccount
                  closeAddAccount={closeAddAccount}
                  selectBank={selectBank}
                  showMessage={showMessage}
                />
              )}
            </LayoutMyAccounts>
          </LayoutOnBoarding>
        )}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  noProduct: {
    padding: "1rem 1rem 4rem 1rem",
    marginTop: "-4rem",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    "& .title": {
      fontFamily: "Montserrat",
      fontSize: "0.9rem",
      fontWeight: "bold",
      color: "#FFFFFF"
    },
    [theme.breakpoints.up(1240)]: {
      padding: "4rem 1rem 0rem 1rem",
    },
  },
  noProductSecond: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    justifyContent: "center",
    "& .subtitle": {
      fontFamily: "Montserrat",
      fontSize: "0.8rem",
      fontWeight: "normal",
      color: "#FFFFFF",
      opacity: "0.6"
    },
    "& .icon": {
      transform: "rotate(-90deg)",
      color: "#FFFFFF",
    } 
  },
  noProductSecondDesktop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",
    "& .subtitle": {
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      fontWeight: "normal",
      color: "#393E44",
      opacity: "0.6"
    },
    "& .icon": {
      height: "2rem",
      width: "2rem",
      transform: "scaleX(-1) rotate(90deg)",
      color: "#0A5669",
    } 
  },
  title: {
    "& img": {
      width: "7rem",
    },
  },
  borderDesktop: {
    [theme.breakpoints.up(1280)]: {
      "& >div": {
        borderRadius: "0",
      },
    },
  },
  noProducts: {
    left: "0px",
    marginTop: "-3rem",
    width: "59%",
    position: "absolute",
    zIndex: 10,
    display: "flex",
    backdropFilter: "blur(3px)",
    color: "white",
    fontSize: 36,
    alignItems: "center",
    fontWeight: "bold",
    justifyContent: "center",
    overflowY: "hidden",
    paddingBottom: "8rem",
    [theme.breakpoints.down(1280)]: {
      marginTop: "-2rem",
      width: "100%",
      height: "18rem",
    },
    [theme.breakpoints.down(850)]: {
      height: "20rem",
    },
    [theme.breakpoints.down(514.5)]: {
      height: "21.5rem",
    },
  },
  noProductsText: {
    marginTop: "8rem",
    [theme.breakpoints.down(1280)]: {
      marginTop: "6rem",
    },
  },
  astronaut: {
    position: "absolute",
    zIndex: 0,
    marginTop: "14rem",
    marginLeft: "11rem",
    [theme.breakpoints.down(1280)]: {
      width: "0rem",
    },
  },
  misProductos: {
    background: "#F4F3F3",
    color: theme.palette.text.primary,
    padding: "1rem",
    borderRadius: "1rem 1rem 0 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "2rem",
    paddingBottom: "8rem"
  },
  containerProd: {
    [theme.breakpoints.up(1240)]: {
      display: "flex",
      alignItems: "start",
      justifyContent: "center",
      padding: "4rem",
      gap: "5rem",
    },
  },
  childrenCorrection: {
    marginTop: "-6rem",
  },
  desktopFirst: {
    width: "28.125rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  tableDesktop: {
    background: "#F4F3F3",
    borderRadius: 15,
    boxShadow: "5.3px 5.3px 10.5px 2.6px rgb(0 0 0 / 20%)",
    padding: "2rem",
    height: "27.25rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  myProductsDesktop: {
    width: "28.125rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    background: "#F4F3F3",
    borderRadius: 15,
    boxShadow: "5.3px 5.3px 10.5px 2.6px rgb(0 0 0 / 20%)",
    padding: "2rem",
    height: "100%",
    minHeight: "29.813rem",
    justifyContent: "start",
    alignItems: "center",
  },
  desktopSecond: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem"
  }
}));
