import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  button: {
    backgroundImage: "linear-gradient(85deg, #4619b6 6%, #c11ec7 94%)",
    height: "3.18rem",
    width: "75%",
    padding: "1rem",
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    borderRadius: "10px",
    boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
    textTransform: "inherit",
    "& .imageContainer": {
      width: "2.063rem",
      height: "2.063rem",
      margin: "0 0.75rem 0 0",
    },
    "& .imgRotate": {
      height: "1.85rem",      
    },
  },
}));

export default useStyles;
