import React, { useCallback, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./AddAccountWithFinerio.style";
import { useCurrentUser } from "@apollo";
import {
  BankSelectorFinerio,
  BANK_OPTIONS,
  EmailInput,
} from "@components/Inputs";
import { finerioListBank } from "@data";
import { FINERIO_ICON } from "images/AWS/myAccount";
import { FinerioAccount, FinerioBankInterface } from "@interfaces";
import { ButtonPrimary } from "@components/Reusables";
import { useForm } from "@hooks";
import { useApolloClient } from "@apollo/client";
import { createFinerioBankRequested } from "apollo/finerio/finerioBankRequested.mutation";
interface AddAccountWithFinerioProps {
  setSelectBank: (value: FinerioBankInterface) => void;
  setStepProduct: (value: number) => void;
  handleNewAccount: (bankId: number) => void;
  finerioAccounts: FinerioAccount[];
}

const AddAccountWithFinerio = ({
  setSelectBank,
  setStepProduct,
  handleNewAccount,
  finerioAccounts,
}: AddAccountWithFinerioProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1280px)");
  const { user } = useCurrentUser();
  const [form, , , handleChange] = useForm({});
  const client = useApolloClient();
  const [sendRequest, setSendRequest] = useState(false);

  const onContinue = (bank: FinerioBankInterface) => {
    setSelectBank(bank);
    if (bank.apiba) {
      setStepProduct(1);
    } else {
      handleNewAccount(bank.finerioBankId);
    }
  };

  const onSend = useCallback(() => {
    const userId = user?.id;
    const bank = form.bank;
    try {
      createFinerioBankRequested(client, { bankRequested: bank, userId });
      setSendRequest(true);
    } catch (error) {
      console.log(error);
    }
  }, [form.bank, form.email, user?.id]);

  const accountsConnectedApiba = finerioAccounts.filter((acc) => acc.accountType !== "")
  const accountsConnectedManual = finerioAccounts.filter((acc) => acc.accountType == "")

  return (
    <section className={classes.container}>
      <p className="subtitle">Bancos sincronizados <span className="subtitleCoolebra">Podrás vincular tus cuentas a Rokin</span></p>
      <div className={classes.bank}>
        {finerioListBank.map((bank) => {
          if (bank.enabled && bank.apiba) {
            const isConnected = accountsConnectedApiba.some((acc) => acc.bankId == bank.finerioBankId.toString())

            return (
              <img
                key={bank.id}
                onClick={() => isConnected ? {} : onContinue(bank)}
                src={bank.img}
                style={isConnected ? {filter: "opacity(50%) "} : {}}
              />
            );
          }
        })}
      </div>
      <p className="subtitle">Bancos manuales <span className="subtitleCoolebra">Podrás ver los beneficios de tus productos</span></p>
      <div className={classes.bank}>
        {finerioListBank.map((bank) => {
          if (bank.enabled && !bank.apiba) {
            const isConnected = accountsConnectedManual.some((acc) => acc.bankId == bank.finerioBankId.toString())
            
            return (
              <img
                key={bank.id}
                onClick={() => isConnected ? {} : onContinue(bank)}
                src={bank.img}
                style={isConnected ? {filter: "opacity(50%) "} : {}}
              />
            );
          }
        })}
      </div>

      {/* TODO: Uncomment when our scrappers are ready */}
{/* 
      <div className={classes.bottomText}>
        Si no ves tu banco te podemos notificar cuando lo tengamos a este
        correo:
        <br></br>
        <br></br>
        <span>CUAL ES TU BANCO?</span>
        <BankSelectorFinerio value={form.bank} onChange={handleChange} />
        <br></br>
        <br></br>
        <span>TU CORREO</span>
        <EmailInput value={form.email} onChange={() => {}} />
      </div>
      <ButtonPrimary
        text="Enviar solicitud"
        onClick={onSend}
        className={classes.boton}
      >
        {" "}
      </ButtonPrimary>
      {sendRequest && (
        <div className={classes.success}>
          <p>
            Hemos recibido tu solicitud, te notificaremos cuando esté disponible
          </p>
        </div>
      )} */}
    </section>
  );
};

export default AddAccountWithFinerio;
