import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./MyExpenses.style";
import { ResponsivePie } from "@nivo/pie";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import {
  AguinaldoIcon,
  AlimentacionIcon,
  ArriendoIcon,
  BonoIcon,
  ComisionIcon,
  CreditIcon,
  CuentasIcon,
  DeportesHobbiesIcon,
  EducationIcon,
  EntretenimientoIcon,
  FinancesAndTaxIcon,
  HonorariosIcon,
  IncomeIcon,
  OthersIcon,
  RegalosIcon,
  RestaurantsIcon,
  SaludIcon,
  SegurosIcon,
  SmallPurchases,
  TransferenciasIcon,
  TransporteIcon,
  ViajesIcon,
  ViviendaIcon,
} from "@images";
import { parseNumber } from "@utils";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { Category, CloseOutlined, Router } from "@material-ui/icons";
import { categorysList } from "@data";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface MyExpensesProps {
  data: {
    name: string;
    total: number;
    amount: number;
    percentage: number;
    type: string;
    months: number;
    average: number;
  }[];
}

export const getCategoryIcon = (name: string) => {
  if (name.toLowerCase() == "sueldo") {
    return { Icon: IncomeIcon, color: "#00A9C2" };
  } else if (name.toLowerCase() == "honorarios") {
    return { Icon: HonorariosIcon, color: "#0A5669" };
  } else if (name.toLowerCase() == "bono") {
    return { Icon: BonoIcon, color: "#00A9C2" };
  } else if (name.toLowerCase() == "aguinaldo") {
    return { Icon: AguinaldoIcon, color: "#C81EC8" };
  } else if (name.toLowerCase() == "alimentación") {
    return { Icon: AlimentacionIcon, color: "#0A5669" };
  } else if (name.toLowerCase() == "arriendo") {
    return { Icon: ArriendoIcon, color: "#4619B6" };
  } else if (name.toLowerCase() == "comision") {
    return { Icon: ComisionIcon, color: "#8352FD" };
  } else if (name.toLowerCase() == "crédito") {
    return { Icon: CreditIcon, color: "#4619B6" };
  } else if (name.toLowerCase() == "cuentas") {
    return { Icon: CuentasIcon, color: "#0A5669" };
  } else if (["entretenimiento", "entretención"].includes(name.toLowerCase())) {
    return { Icon: EntretenimientoIcon, color: "#EBBC29" };
  } else if (name.toLowerCase() == "vivienda") {
    return { Icon: ViviendaIcon, color: "#00A9C2" };
  } else if (["pequeñas compras", "compras"].includes(name.toLowerCase())) {
    return { Icon: SmallPurchases, color: "#EBBC29" };
  } else if (
    ["salud", "belleza", "salud y belleza"].includes(name.toLowerCase())
  ) {
    return { Icon: SaludIcon, color: "#008296" };
  } else if (name.toLowerCase() == "seguros") {
    return { Icon: SegurosIcon, color: "#7D7C7C" };
  } else if (name.toLowerCase() == "transporte") {
    return { Icon: TransporteIcon, color: "#8352FD" };
  } else if (name.toLowerCase() == "transferencias") {
    return { Icon: TransferenciasIcon, color: "#0A5669" };
  } else if (name.toLowerCase() == "viajes") {
    return { Icon: ViajesIcon, color: "#7D7C7C" };
  } else if (name.toLowerCase() == "deportes y hobbies") {
    return { Icon: DeportesHobbiesIcon, color: "#393E44" };
  } else if (name.toLowerCase() == "educación y trabajo") {
    return { Icon: EducationIcon, color: "#4619B6" };
  } else if (name.toLowerCase() == "finanzas e impuestos") {
    return { Icon: FinancesAndTaxIcon, color: "#7D107D" };
  } else if (name.toLowerCase() == "restaurantes") {
    return { Icon: RestaurantsIcon, color: "#9903E0" };
  } else if (name.toLowerCase() == "regalos") {
    return { Icon: RegalosIcon, color: "#41486E" };
  } else {
    return { Icon: OthersIcon, color: "#210565" };
  }
};

const MyExpensesGraphic = ({ data }: MyExpensesProps) => {
  const isMobile = useMediaQuery("(max-width: 700px)");
  const arrayColors: any[] = [];

  const dataPie: any[] = [];

  let totalExpense = data?.categories
    .map((item) => item.expense)
    .reduce((a, b) => a + b);

  data?.categories.map((d) => {
    const category = categorysList.find((item) => item.name === d.name);

    arrayColors.push(category?.backgroundColor);
    dataPie.push({
      id: d.name,
      label: d.name,
      value: ((d.expense * 100) / totalExpense).toFixed(1),
      color: category?.backgroundColor,
    });
  });

  const classes = useStyles();

  const handleOnClick = (info: any) => {
    const categoryAmount = data?.categories.find(
      (item) => item.name === info.id
    )?.expense;
    const categoryTransactions = data?.categories.find(
      (item) => item.name === info.id
    )?.transactions;
    const averageArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    data?.categories
      .find((item) => item.name === info.id)
      ?.transactions.map((trans, index) => {
        const month = trans.date.split("-")[1];
        if (month[0] === "0") {
          averageArray[month[1]] += trans.amount;
        } else {
          averageArray[month] += trans.amount;
        }
      });

    navigate(Route.habitsDetails, {
      state: {
        name: info.id,
        amount: categoryAmount,
        total: totalExpense,
        percentage: info.value,
        average: averageArray,
        transactions: categoryTransactions,
      },
    });
  };

  return (
    <div className={classes.container}>
      <ResponsivePie
        data={dataPie ?? []}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.65}
        padAngle={2}
        cornerRadius={1}
        activeOuterRadiusOffset={8}
        colors={arrayColors}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        enableArcLinkLabels={isMobile ? false : true}
        arcLinkLabelsSkipAngle={6}
        arcLinkLabelsTextOffset={6}
        arcLinkLabelsTextColor={{ from: "color", modifiers: [] }}
        arcLinkLabelsDiagonalLength={16}
        arcLinkLabelsStraightLength={24}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        enableArcLabels={isMobile ? true : false}
        arcLabelsSkipAngle={10}
        arcLabelsRadiusOffset={0.4}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        legends={[]}
        onClick={(data, event) => {
          handleOnClick(data);
        }}
      />{" "}
    </div>
  );
};

export default MyExpensesGraphic;
