import React, { useCallback, useEffect, useState } from 'react';
import { Button, useMediaQuery } from '@material-ui/core';
import useStyles from './Habits.style';
import MyBalanceTable from './MyBalance/MyBalanceTable';
import MyExpensesGraphic from './MyExpenses/MyExpenses';
import { MovementsSelector } from '@components/Inputs/SingleSelectors/MovementsSelector';
import MovementItem from './MovementItem/MovementItem';
import MyExpensesTable from './MyExpenses/MyExpensesTable';
import { GraphicIconOff, ListIcon } from 'images/Investments/Goals';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {
  ADD_BUTTON_ICON,
  INFO_ICON,
  META_ICON,
  SALDO_ICON,
} from 'images/AWS/habits';
import {
  ARROW_BACK_ICON,
  astronautMagnifyingGlass,
  recomendationIcon,
} from '@images';
import { Route } from '@interfaces';
import { navigate } from 'gatsby';
import { getFinerioTransactions, updateUser } from '@apollo';
import { ProgressBarLine } from 'react-progressbar-line';
import { finerioTransactionsByCategory, parseNumber } from '@utils';
import { getFinerioAccounts } from '@apollo';
import { CloseRounded } from '@material-ui/icons';
import { LABEL_LIGHT_BLUE } from 'images/AWS/rokipedia';
import { BudgetRecommendations } from '@components/Missions';
import { ButtonPrimary, NumericInput } from '@components/Reusables';
import {
  ALFRED_MOBILE,
  ALFRED_DESKTOP,
  ALFRED_MOBILE_NO_CLOSE,
  ALFRED_DESKTOP_NO_CLOSE,
  ALFRED_SECOND_TEXT,
} from 'images/GCP';
import ChartCategories from '../components/chartCategories';
import AddMovementButton from '../components/addMovementButton';
import { Select } from '@components/Reusables';
import { currentUserPreferences } from '@apollo';
import { useForm } from '@hooks';
import { useApolloClient } from '@apollo/client';

interface HabitsProps {
  title?: string;
  togglePage: () => void;
}

const MyHabits = ({ title, togglePage }: HabitsProps) => {
  const isMobile = useMediaQuery('(max-width: 1280px)');
  const [isGraphic, setGraphic] = useState(true);
  const classes = useStyles({ isGraphic: isGraphic });
  const client = useApolloClient();
  const [form, , , handleChange] = useForm({});
  const preferences = currentUserPreferences();
  const [isTransactions, setTransactions] = useState<boolean>(false);
  const { finerioAccounts } = getFinerioAccounts();
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  const { finerioTransactions } = getFinerioTransactions();
  const finerioData = finerioTransactionsByCategory(finerioTransactions);
  finerioData.reverse();

  //Closest date
  const temp = finerioData.map((d) => {
    return Math.abs(new Date() - new Date(d.date).getTime());
  });
  const idx = temp.indexOf(Math.min(...temp));

  const [selectedMonth, setSelectedMonth] = useState(idx);
  const closestDate = finerioData[selectedMonth];
  const previousMonth = finerioData[selectedMonth - 1];

  useEffect(() => {
    setTransactions(finerioTransactions.length !== 0);
  }, [finerioTransactions]);

  useEffect(() => {
    setSelectedMonth(idx);
  }, [idx]);

  useEffect(() => {
    if (isMobile) {
      setGraphic(false);
    }
  }, [isMobile]);

  const infoButtonHandler = () => {
    //OPEN POPUP
    setOpenInfoPopUp(!openInfoPopUp);
  };

  const monthOptions = useCallback(() => {
    let toReturn: { name: string; value: number }[] = [];
    finerioData.map((item, index) => {
      let newDate = new Date(item.date);
      let year = newDate.toLocaleString('es-ES', { year: 'numeric' });
      let monthName = newDate.toLocaleString('es-ES', { month: 'short' });
      toReturn.push({ name: `${monthName}-${year}`, value: index });
    });
    return toReturn;
  }, [finerioTransactions]);

  const getBudgetGoal = (categoryName: string) => {
    const budgetGoal = preferences.budgetGoals?.find(
      (item) => Object.keys(item)[0] === categoryName
    );
    return budgetGoal ? budgetGoal[categoryName] : 0;
  };
  const lastGlobalGoal = getBudgetGoal('GlobalGoal');
  const [globalGoal, setGlobalGoal] = useState(lastGlobalGoal);

  const handlePopUpOnClick = useCallback(
    (goal) => {
      const newBudgetGoal = { GlobalGoal: goal };
      if (preferences === undefined || preferences.budgetGoals === undefined) {
        preferences.budgetGoals = [];
      }
      const index = preferences.budgetGoals.findIndex(
        (item) => Object.keys(item)[0] === 'GlobalGoal'
      );
      // if already exists, update
      if (index !== -1) {
        const newBudgetGoals = preferences?.budgetGoals.map((obj) => {
          if (Object.keys(obj)[0] === 'GlobalGoal') {
            return newBudgetGoal;
          }
          return obj;
        });
        form.preferences = {
          onBoarding: preferences?.onBoarding,
          budgetGoals: newBudgetGoals,
        };
        console.log(form.preferences);
      } else {
        // if not, add it to the array
        const newBudgetGoals = [...preferences?.budgetGoals, newBudgetGoal];
        form.preferences = {
          onBoarding: preferences?.onBoarding,
          budgetGoals: newBudgetGoals,
        };
      }
      setOpenInfoPopUp(false);
      updateUser(form, false, client);
    },
    [form, preferences, client]
  );

  const addMovementsDiv = () => {
    return (
      <>
        <div className={classes.addMovements}>
          <AddMovementButton />
          <div className={classes.titleMonth}>
            <h2 className={classes.titleSpentSection}>Gasto Total</h2>
            <div className={classes.selectMonth}>
              <Select
                options={monthOptions()}
                onChange={(value) => setSelectedMonth(value.target.value)}
                value={selectedMonth}
                disableError={true}
                underscore
              />
            </div>
          </div>
          <section
            className={classes.containerTotal}
            onClick={() => infoButtonHandler()}
          >
            <img src={META_ICON} />
            <div>
              <span className='title'>
                Total: ${parseNumber(closestDate?.expense ?? 0)}
              </span>
              <ProgressBarLine
                value={closestDate?.expense}
                min={0}
                max={previousMonth?.expense}
                text=' '
                styles={{
                  path: {
                    stroke: '#008296',
                  },
                }}
              />
              <span className='meta'>
                Meta de gasto: ${' '}
                {globalGoal === 0 || null
                  ? parseNumber(lastGlobalGoal)
                  : parseNumber(globalGoal)}
              </span>
            </div>
          </section>
        </div>
        <div
          className={
            openInfoPopUp ? classes.popUpContainer : classes.hiddenDisplay
          }
        >
          <div className={classes.outerBorder}>
            <div className={classes.popUp}>
              <div className={classes.itemRow}>
                <h3 className={classes.rokipediaPopUpTitle}>Meta de gasto</h3>
                <button onClick={infoButtonHandler}>
                  <CloseRounded htmlColor='#393E44' />
                </button>
              </div>
              <div className={classes.popUpVector}></div>
              <span className={classes.popUpText}>
                Crea o modifica tu meta de gasto para que puedas ver tu
                progreso.
              </span>
              <div className={classes.goalInput}>
                <NumericInput
                  className='input'
                  name='globalGoal'
                  value={globalGoal}
                  onChange={(e) => {
                    setGlobalGoal(e.target.value);
                  }}
                />
              </div>
              <div className={classes.buttonGotItContainer}>
                <ButtonPrimary
                  className={classes.buttonGotIt}
                  onClick={() => {
                    handlePopUpOnClick(globalGoal);
                  }}
                  text='Entendido'
                  secondaryVersion
                  noDegraded
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isMobile ? (
        <>
          <div className={classes.containerAll}>
            {addMovementsDiv()}
            {isTransactions && <ChartCategories data={closestDate} />}
          </div>
          {isTransactions && (
            <section className={classes.movementsContainer}>
              <div className={classes.transactionsTable}>
                <h2 className={classes.titleSpentSection}>Mis movimientos</h2>
                <AddMovementButton />
              </div>
              <MovementItem data={finerioTransactions} />
            </section>
          )}
        </>
      ) : (
        <>
          <section className={classes.habitsWholeContainerDesktop}>
            <div className={classes.expensesBoxDesktop}>
              {addMovementsDiv()}
            </div>
            <ChartCategories data={closestDate} />
          </section>
          <section className={classes.movementsContainer}>
            <div className={classes.transactionsTable}>
              <h2 className={classes.titleSpentSection}>Mis movimientos</h2>
              <AddMovementButton />
            </div>
            <div className={isMobile ? '' : classes.table}>
              <MovementItem data={finerioTransactions} />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default MyHabits;
