import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  habitsWholeContainerDesktop: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "6rem",
    padding: "4rem 8rem 4rem 8rem",
  },
  containerAll: {
    background: "#F4F3F3",
    borderRadius: "1.5rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  alfredContainer: {
    height: "5.75rem",
    [theme.breakpoints.down(1280)]: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      marginTop: "2rem",
      marginBottom: "2rem",
    },
  },
  addMovements: {
    padding: "2rem 1rem 1rem 1rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    [theme.breakpoints.up(1280)]: {
      background: "#F4F3F3",
      borderRadius: 15,
      boxShadow: "5.3px 5.3px 10.5px 2.6px rgb(0 0 0 / 20%)",
      width: "26.875rem",
    },
  },
  titleSpentSection: {
    fontWeight: 300,
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    width: "100%",
  },
  containerTotal: {
    cursor: "pointer",
    boxShadow: "5.3px 5.3px 10.5px 2.6px rgba(0, 0, 0, 0.2)",
    borderRadius: 15,
    padding: "0.5rem 1rem",
    display: "flex",
    background: "white",
    gap: "1rem",
    [theme.breakpoints.down(1280)]: {
      width: "100%",
    },
    "& .meta": {
      fontSize: "0.8rem",
      fontFamily: theme.typography.fontFamily,
      color: "#6B6B6B",
    },
    "& .title": {
      fontFamily: theme.typography.fontFamily,
      color: "#393E44",
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    "& svg": {
      borderRadius: "15px",
    },
  },
  movementsContainer: {
    margin: "3rem auto 9rem",
    borderRadius: "1rem 1rem 0 0",
    background: "#F4F3F3",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    alignItems: "center",
  },
  expensesBoxDesktop: {
    display: "flex",
    flexDirection: "column",
    gap: "4rem",
  },
  transactionsTable: {
    padding: "2rem 1rem 1rem 1rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    width: "95%",
    [theme.breakpoints.up(1280)]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  table: {
    display: "flex",
    width: "98%",
  },
  popUpContainer: {
    zIndex: 1000,
    display: "flex",
    position: "fixed",
    top: 0,
    left: 0,
    background: "rgba(0, 0, 0, 0.8)",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    paddingTop: "10%",
    [theme.breakpoints.down(1280)]: {
      paddingTop: "5%",
    },
  },
  hiddenDisplay: {
    display: "none",
  },
  outerBorder: {
    width: "43.938rem",
    height: "auto",
    borderRadius: "40px",
    // border: "1px solid #ffffff",
    padding: "0.25rem",
    [theme.breakpoints.down(1280)]: {
      width: "80%",
      height: "36.188rem",
    },
  },
  popUp: {
    width: "100%",
    height: "auto",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderRadius: "35px",
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
    [theme.breakpoints.down(1280)]: {
      borderRadius: "35px",
    },
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rokipediaPopUpTitle: {
    fontFamily: "Nunito, Montserrat",
    fontSize: "1rem",
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "normal",
    color: "#393e44",
  },
  popUpVector: {
    width: "100%",
    height: "0.125rem",
    backgroundColor: "#ffffff",
    borderRadius: "2px",
    margin: "0.7rem 0 0 0",
    [theme.breakpoints.down(1280)]: {},
  },
  buttonGotItContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(1280)]: {
      margin: "1.5rem 0 0 0",
    },
  },
  buttonGotIt: {
    margin: "1.2rem 0 0 0",
    width: "3rem",
  },
  popUpText: {
    marginTop: "1rem",
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: "1rem",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#393E44",
  },
  titleMonth: {
    display: "flex",
    alignItems: "center",
  },
  selectMonth: {},
  goalInput: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '1rem',
  },
}));

export default useStyles;
