import React, { useState } from "react";
import useStyles from "./rokinAdvice.style";
import { ARROW_BACK_ICON, FlechaBudget, recomendationIcon } from "@images";
import { CloseRounded } from "@material-ui/icons";
import { BudgetRecommendations } from "@components/Missions";
import { ButtonPrimary } from "@components/Reusables";
import { finerioTransactionsByCategory } from "@utils";
import { getFinerioTransactions } from "@apollo";
import { LIGHTBULB } from "images/GCP";

interface propsRokinAdvice {
  selectedMonth: number;
}

const RokinAdvice = ({ selectedMonth }: propsRokinAdvice) => {
  const classes = useStyles();
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);
  const { finerioTransactions } = getFinerioTransactions();
  const finerioData = finerioTransactionsByCategory(finerioTransactions);

  const infoButtonHandler = () => {
    //OPEN POPUP
    setOpenInfoPopUp(!openInfoPopUp);
  };

  return (
    <section className={classes.consejoRokin} onClick={() => infoButtonHandler()}>
      <img className={"imgBulb"} src={LIGHTBULB} />
      <div>
        <span className="title">QUIERO MEJORAR MIS HÁBITOS</span>
        <span className="subtitle">
            Ve los consejos personalizados que tenemos para ti
        </span>
      </div>
      <div
        className={
          openInfoPopUp ? classes.popUpContainer : classes.hiddenDisplay
        }
      >
        <div className={classes.outerBorder}>
          <div className={classes.popUp}>
            <div className={classes.itemRow}>
              <img src={recomendationIcon} alt="" />
              <h3 className={classes.rokipediaPopUpTitle}>Análisis y tips</h3>
              <button onClick={infoButtonHandler}>
                <CloseRounded htmlColor="#393e44" />
              </button>
            </div>
            <div className={classes.popUpVector}></div>
            <BudgetRecommendations
              data={finerioData[selectedMonth]}
              fromFinerio={true}
            />
            <div className={classes.buttonGotItContainer}>
              <ButtonPrimary
                className={classes.buttonGotIt}
                onClick={() => {
                  infoButtonHandler();
                }}
                text="Entendido"
                secondaryVersion
                noDegraded
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RokinAdvice;
