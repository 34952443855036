import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  consejoRokin: {
    width: "100%",
    height: "4rem",
    cursor: "pointer",
    borderRadius: 15,
    fontFamily: "Montserrat",
    display: "flex",
    padding: "0.5rem 1rem",
    alignItems: "center",
    gap: "1rem",
    border: "1px solid #084554!important",
    "& imgBulb": {
      height: "1.3rem",
      marginRight: "1rem",
    },
    "& .title": {
      color: "#084554",
      fontSize: "0.75rem",
      fontWeight: 500,
      display: "block",
    },
    "& .subtitle": {
      color: "#6B6B6B",
      fontSize: "0.65rem",
      display: "block",
      fontWeight: 300,
    },
  },
  popUpContainer: {
    zIndex: 1000,
    display: "flex",
    position: "fixed",
    top: 0,
    left: 0,
    background: "rgba(0, 0, 0, 0.8)",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    paddingTop: "10%",
    [theme.breakpoints.down(1280)]: {
      paddingTop: "5%",
    },
  },
  hiddenDisplay: {
    display: "none",
  },
  outerBorder: {
    width: "43.938rem",
    height: "auto",
    borderRadius: "40px",
    // border: "1px solid #ffffff",
    padding: "0.25rem",
    [theme.breakpoints.down(1280)]: {
      width: "80%",
      height: "36.188rem",
    },
  },
  popUp: {
    width: "100%",
    height: "auto",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderRadius: "35px",
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
    [theme.breakpoints.down(1280)]: {
      borderRadius: "35px",
    },
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rokipediaPopUpTitle: {
    fontFamily: "Nunito, Montserrat",
    fontSize: "1rem",
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "normal",
    color: "#393e44",
  },
  popUpVector: {
    width: "100%",
    height: "0.125rem",
    backgroundColor: "#ffffff",
    borderRadius: "2px",
    margin: "0.7rem 0 0 0",
    [theme.breakpoints.down(1280)]: {},
  },
  buttonGotItContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(1280)]: {
      margin: "2.5rem 0 0 0",
    },
  },
  buttonGotIt: {
    margin: "1.2rem 0 0 0",
    width: "3rem",
  },
}));

export default useStyles;
