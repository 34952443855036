import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<
  Theme,
  {
    isGraphic: boolean;
  }
>((theme) => ({
  graphicSpents: {
    boxShadow: "5.3px 5.3px 10.5px 2.6px rgb(0 0 0 / 20%)",
    borderRadius: 15,
    background: "white",
    width: "100%",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    fontFamily: "Montserrat",
  },
  topGraphicSpents: {
    display: "flex",
  },
  optionsTopGraphicSpents: {
    display: "flex",
    alignItems: "center",
    "& .graphic": {
      cursor: "pointer",
      border: ({ isGraphic }) =>
        isGraphic
          ? `1px solid ${theme.palette.primary.dark}`
          : `1px solid ${theme.palette.text.secondary}`,
      background: ({ isGraphic }) =>
        isGraphic ? `rgba(0, 130, 150, 0.1)` : `none`,
      borderRight: ({ isGraphic }) =>
        !isGraphic ? "none" : `1px solid ${theme.palette.primary.main}`,
      padding: "0.5rem 0.5rem",
      display: "flex",
      gap: "0.2rem",
      alignItems: "center",
      borderRadius: "0.5rem 0rem 0 0.5rem",
      fontSize: "0.7rem",
      "& img": {
        width: "1rem",
      },
    },
    "& .table": {
      cursor: "pointer",
      border: ({ isGraphic }) =>
        !isGraphic
          ? `1px solid ${theme.palette.primary.dark}`
          : `1px solid ${theme.palette.text.secondary}`,
      borderLeft: ({ isGraphic }) =>
        isGraphic ? "none" : `1px solid ${theme.palette.primary.main}`,
      background: ({ isGraphic }) =>
        !isGraphic ? `rgba(0, 130, 150, 0.1)` : `none`,
      padding: "0.5rem",
      display: "flex",
      gap: "0.2rem",
      fontSize: "0.7rem",
      alignItems: "center",
      borderRadius: "0rem 0.5rem  0.5rem 0",
      "& img": {
        width: "1rem",
      },
    },
  },
  graphic: {
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(1280)]: {
      width: "100%",
    },
  },
}));

export default useStyles;
