import React, { useEffect, useState } from "react";
import useStyles from "./chartCatergories.style";
import { GraphicIconOff, ListIcon } from "images/Investments/Goals";
import MyExpensesGraphic from "../Habits/MyExpenses/MyExpenses";
import MyExpensesTable from "../Habits/MyExpenses/MyExpensesTable";
import { useMediaQuery } from "@material-ui/core";

interface propsDataGoal {
  data: any;
}

const ChartCategories = ({ data }: propsDataGoal) => {
  const isMobile = useMediaQuery("(max-width: 1280px)");
  const [isGraphic, setGraphic] = useState(false);
  const classes = useStyles({ isGraphic: isGraphic });
  
  return (
    <div className={classes.graphicSpents}>
      <div className={classes.topGraphicSpents}>
          <div className={classes.optionsTopGraphicSpents}>
          <div className="graphic" onClick={() => setGraphic(true)}>
              {/* <img src={GraphicIcon} /> */}{" "}
              <img src={GraphicIconOff} />
              Ver gráfico
          </div>
          <div className="table" onClick={() => setGraphic(false)}>
              <img src={ListIcon} />
              {/*  <img src={ListIconOn} /> */}
              Ver tabla
          </div>
          </div>
      </div>
      <div className={classes.graphic}>
          {isGraphic ? (
          <MyExpensesGraphic data={data} />
          ) : (
          <MyExpensesTable data={data} />
          )}
      </div>{" "}
    </div>
  );
};

export default ChartCategories;
