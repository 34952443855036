import React from "react";
import useStyles from "./coolebraButton.style";
import { Button } from "@material-ui/core";
import { COOLEBRA_ICON_PURPLE } from "@images";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { getFinerioAccounts } from "@apollo";
import { finerioListBank } from "@data";

interface coolebraButtonProps {
  banks?: boolean;
}

const CoolebraButton = ({banks}: coolebraButtonProps) => {
  const classes = useStyles();
  const { finerioAccounts } = getFinerioAccounts();

  let bankNames: string[] = []
  
  finerioAccounts.map((bank) => {
    const name = finerioListBank.filter((item) => item.finerioBankId === Number(bank.bankId))[0].name
    if(name !== "Billetera en efectivo"){
      bankNames.push(name)
    }
  })
  
  const handleClick = () => {
    if(banks){
      navigate(Route.coolebra, {
        state: {
          selectedBanks: bankNames,
        },
      });
    } else {
      navigate(Route.coolebra);
    }
  };

  return (
    <Button className={classes.button} onClick={() => handleClick()}>
      <div className="imageContainer">
        <img src={COOLEBRA_ICON_PURPLE} alt="apv" />
      </div>
      <span>Revisa tus descuentos</span>
      <ArrowForwardIosRoundedIcon
        className="imgRotate"
      />
    </Button>
  );
};

export default CoolebraButton;
